import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React, { useEffect, useState } from 'react';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { closeModal } from '@autocut/utils/modal/closeModal';
import { CURRENT_ENV, Env } from '@autocut/utils/currentEnv.utils';
import { os, child_process } from '@autocut/lib/cep/node';
import path from 'path';
import { colors } from '@autocut/designSystem/colors';

import autocutV2Beta from '@autocut/assets/images/migration/AutoCut V2 Beta.png';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { useLocale } from '@autocut/hooks/useLocale';
import { autocutApi } from '@autocut/utils';
import { setAutocutStore } from '@autocut/utils/zustand';
import { LoadingRing } from '@autocut/designSystem/components/atoms/LoadingRing/LoadingRing';

const startElectronApp = async () => {
  const platform = os.platform();
  const appName = CURRENT_ENV === Env.Production ? 'AutoCut' : 'AutoCutStaging';
  const appPath =
    platform === 'win32'
      ? path.join(os.homedir(), 'AppData', 'Local', appName, `${appName}.exe`)
      : path.join('/Applications', `${appName}.app`);

  if (platform === 'win32') {
    await new Promise<void>(resolve => {
      child_process.spawn(appPath, { detached: true, stdio: 'ignore' }).unref();
      resolve();
    });
  } else {
    await new Promise<void>(resolve => {
      child_process.exec(`open "${appPath}"`, (error, stdout, stderr) => {
        if (error) {
          console.error(`exec error: ${error}`);
          return;
        }
        console.log(`stdout: ${stdout}`);
        console.error(`stderr: ${stderr}`);
      });
      resolve();
    });
  }
};

export const LaunchV2Modal = () => {
  const { locale } = useLocale();
  const [isLoading, setIsLoading] = useState(true);
  const [body, setBody] = useState('');
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const init = async () => {
      const launchModal = (
        await autocutApi.get('launch-modals/launch-v2-modal')
      ).data;
      const localBody = launchModal.body[locale];
      const localFeatures = launchModal.features[locale];

      setBody(localBody);
      setFeatures(localFeatures);
      setAutocutStore('user.lastSeenLaunchV2Modal', Date.now());
      setIsLoading(false);
    };

    void init();
  }, [locale]);

  if (isLoading) {
    return (
      <Modal
        title={
          <Text variant="textXl.bold" color="white">
            <TranslatedMessage
              id="launchV2Modal_title"
              defaultMessage="New features on AutoCut 2.0"
            />
          </Text>
        }
        fullScreen
        withCloseButton={false}
        closeOnClickOutside={false}
      >
        <FlexContainer alignItems="center" justifyContent="center">
          <LoadingRing />
        </FlexContainer>
      </Modal>
    );
  }

  return (
    <Modal
      withCloseButton={false}
      closeOnClickOutside={false}
      title={
        <Text variant="textXl.bold" color="white">
          <TranslatedMessage
            id="launchV2Modal_title"
            defaultMessage="New features on AutoCut 2.0"
          />
        </Text>
      }
      footer={
        <FlexContainer flexDirection="column" gap={Spacing.s2}>
          <Button
            color={colors.primary600}
            onClick={async () => {
              await startElectronApp();
              process.exit();
            }}
          >
            <TranslatedMessage
              id="launchV2Modal_launchV2"
              defaultMessage={'Launch AutoCut 2.0'}
            />
          </Button>
          <Button variant="tertiary" onClick={() => closeModal()}>
            <TranslatedMessage
              id="launchV2Modal_continueV1"
              defaultMessage={'Continue on AutoCut V1'}
            />
          </Button>
        </FlexContainer>
      }
      fullScreen
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
        style={{ height: '100%' }}
      >
        <Text variant="textSm.medium" color="white">
          {body}
        </Text>
        <Text variant="textSm.medium" color="white">
          <TranslatedMessage
            id="launchV2Modal_exemples"
            defaultMessage={'Here are some exemples :'}
          />
        </Text>
        <ul
          style={{
            color: 'white',
          }}
        >
          {features.map(feature => (
            <li key={feature}>
              <Text variant="textSm.medium" color="white">
                {feature}
              </Text>
            </li>
          ))}
        </ul>
        <Text variant="textSm.medium" color="white">
          <TranslatedMessage
            id="launchV2Modal_expectations"
            defaultMessage={
              'If AutoCut 2.0 does not meet your expectations, please send feedback to <span>contact@autocut.fr</span>.'
            }
            values={{
              span: chunks => (
                <span
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => openLinkInBrowser('mailto:contact@autocut.fr')}
                >
                  <b>{chunks}</b>
                </span>
              ),
            }}
          />
        </Text>

        <FlexContainer
          alignItems="center"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <img
            src={autocutV2Beta}
            alt="AutoCut V2 Beta"
            style={{
              width: 300,
            }}
          />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
