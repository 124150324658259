import React, { useMemo } from 'react';

import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { getRandomAuthor } from '@autocut/components/atoms/Author/utils';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';
import { closeModal } from '@autocut/utils/modal/closeModal';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { Author } from '@autocut/components/atoms/Author/Author';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';

import { NewFeaturesList } from './NewFeature';

export const OnboardingMigrationModal = () => {
  const randomAuthor = useMemo(() => getRandomAuthor(), []);

  return (
    <Modal
      title={
        <TranslatedMessage
          id="onboarding_migration_title"
          defaultMessage="Autocut 2.0: Get ready for takeoff! 🚀"
        />
      }
      withCloseButton={false}
      closeOnClickOutside={false}
      footer={
        <FlexContainer flexDirection="column" gap={8} style={{ marginTop: 8 }}>
          <Button
            color={colors.primary600}
            onClick={() => {
              openLinkInBrowser('https://www.autocut.com/en/download-beta/');
            }}
          >
            <TranslatedMessage
              id="onboarding_migration_upgrade"
              defaultMessage="Upgrade now to AutoCut 2.0"
            />
          </Button>
          <Button onClick={closeModal} variant="secondary">
            <TranslatedMessage
              id="onboarding_migration_continue"
              defaultMessage="Continue on AutoCut V1"
            />
          </Button>
        </FlexContainer>
      }
    >
      <FlexContainer flexDirection="column" gap={16}>
        <FormSection title={<Author author={randomAuthor} />}>
          <PaddedSection>
            <TranslatedMessage
              id="onboarding_migration_text"
              defaultMessage="After months of development and a total rebuild from scratch, AutoCut 2.0 is now available for download!{br}{br}Try it now to discover all its improvements in performance and stability.{br}{br}After trying it, share your thoughts with us about this new version 2.0; we will continuously improve it!"
            />
          </PaddedSection>
        </FormSection>

        <NewFeaturesList />
      </FlexContainer>
    </Modal>
  );
};
