import React from 'react';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { colors } from '@autocut/designSystem/colors';
import { PiMicrophoneLight, PiWaveformLight } from 'react-icons/pi';
import { FiSave } from 'react-icons/fi';

export const NewFeature = ({
  title,
  icon,
  description,
}: {
  title: string;
  icon: React.ReactNode;
  description: React.ReactNode;
}) => {
  return (
    <FlexContainer
      gap={2}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ maxWidth: '30%' }}
    >
      <Text variant="textXl.bold" color="white">
        {title}
      </Text>
      {icon}
      <Text
        variant="textSm.medium"
        style={{ textAlign: 'center', fontStyle: 'italic' }}
        color="white"
      >
        {description}
      </Text>
    </FlexContainer>
  );
};

export const NewFeaturesList = () => {
  return (
    <FlexContainer
      flexDirection="row"
      style={{
        width: '100%',
      }}
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <NewFeature
        title="Silences"
        icon={
          <PiWaveformLight
            color={colors.primary600}
            size={24}
            strokeWidth={1.5}
          />
        }
        description={
          <TranslatedMessage
            id="onboarding_migration_feature_silences"
            defaultMessage="Faster silences deletion.{br}Edit in no time."
          />
        }
      />
      <NewFeature
        title="Captions"
        icon={<FiSave color={colors.primary600} size={24} strokeWidth={1.5} />}
        description={
          <TranslatedMessage
            id="onboarding_migration_feature_caption"
            defaultMessage="Transcription historic.{br}Captions saved."
          />
        }
      />
      <NewFeature
        title="Podcast"
        icon={
          <PiMicrophoneLight
            color={colors.primary600}
            size={24}
            strokeWidth={1.5}
          />
        }
        description={
          <TranslatedMessage
            id="onboarding_migration_feature_podcast"
            defaultMessage="Algorithm optimised.{br}Podcast, ready."
          />
        }
      />
    </FlexContainer>
  );
};
