import adrianThumbnail from '@autocut/assets/images/authors/adrian.png?url';
import alexandreThumbnail from '@autocut/assets/images/authors/alexandre.png?url';
// import olivierThumbnail from '@autocut/assets/images/authors/olivier.png?url';
import maximeThumbnail from '@autocut/assets/images/authors/maxime.png?url';
import amelieThumbnail from '@autocut/assets/images/authors/amelie.png?url';
import myriamThumbnail from '@autocut/assets/images/authors/myriam.png?url';
import arthurThumbnail from '@autocut/assets/images/authors/arthur.png?url';

const adrianThumbnailUrl = new URL(adrianThumbnail, import.meta.url).href;
const alexandreThumbnailUrl = new URL(alexandreThumbnail, import.meta.url).href;
// const olivierThumbnailUrl = new URL(olivierThumbnail, import.meta.url).href;
const maximeThumbnailUrl = new URL(maximeThumbnail, import.meta.url).href;
const amelieThumbnailUrl = new URL(amelieThumbnail, import.meta.url).href;
const myriamThumbnailUrl = new URL(myriamThumbnail, import.meta.url).href;
const arthurThumbnailUrl = new URL(arthurThumbnail, import.meta.url).href;

export enum EAuthors {
  ADRIAN = 'adrian',
  ALEXANDRE = 'alexandre',
  // OLIVIER = 'olivier',
  MAXIME = 'maxime',
  AMELIE = 'amelie',
  MYRIAM = 'myriam',
  ARTHUR = 'arthur',
}

export const getRandomAuthor = (): EAuthors => {
  const authors = Object.values(EAuthors);
  return authors[Math.floor(Math.random() * authors.length)];
};

export const Authors: Record<EAuthors, { name: string; picture: string }> = {
  [EAuthors.ADRIAN]: {
    name: 'Adrian',
    picture: adrianThumbnailUrl,
  },
  [EAuthors.ALEXANDRE]: {
    name: 'Alexandre',
    picture: alexandreThumbnailUrl,
  },
  // [EAuthors.OLIVIER]: {
  //   name: 'Olivier',
  //   picture: 'olivierThumbnail',
  // },
  [EAuthors.MAXIME]: {
    name: 'Maxime',
    picture: maximeThumbnailUrl,
  },
  [EAuthors.AMELIE]: {
    name: 'Amelie',
    picture: amelieThumbnailUrl,
  },
  [EAuthors.MYRIAM]: {
    name: 'Myriam',
    picture: myriamThumbnailUrl,
  },
  [EAuthors.ARTHUR]: {
    name: 'Arthur',
    picture: arthurThumbnailUrl,
  },
};
