import { colors } from '@autocut/designSystem/colors';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import React from 'react';

import css from './MigrateBanner.module.css';
import { TranslatedMessage } from '../TranslatedMessage/TranslatedMessage';

export const MigrateBanner = () => {
  return (
    <FlexContainer
      alignItems="center"
      justifyContent="center"
      className={css.container}
      onClick={() =>
        openLinkInBrowser('https://www.autocut.com/download-beta/')
      }
    >
      <Text variant="textSm" color="white" className={css.text}>
        <TranslatedMessage
          id="migrate_banner_text"
          defaultMessage="AutoCut V2 is available, much more stable and performant, download it here: "
        />
        <Button variant="tertiary" color="white" fullWidth={false}>
          <TranslatedMessage
            id="migrate_banner_cta"
            defaultMessage="Download AutoCut V2"
          />
        </Button>
      </Text>
    </FlexContainer>
  );
};
