import { keyRegisterOverride } from '@autocut/lib/utils/cep';
import logLevel from '@autocut/types/logLevel.enum';
import { CEPProcessManager } from '@autocut/utils/cepProcessus/CEPProcessManager';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { getKey } from '@autocut/utils/localStorage.utils';
import { manageError } from '@autocut/utils/manageError';
import { loadStartUpModals } from '@autocut/utils/modal/startUpModal';
import { fillLocalStorageWithPersistanceStorage } from '@autocut/utils/persistanceStorage.utils';
import { manuallyRefreshSequence } from '@autocut/utils/sequence.utils';
import { refreshClientInfos } from '@autocut/utils/system/system.utils';

import { ModalType } from '@autocut/enums/modals.enum';
import { loadUserLocalFonts } from '@autocut/modes/captions/Steps/Customization/Parts/Fonts/fonts.utils';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';
import { isKeyValid } from './auth.utils';
import { getScoreFromApi } from './game/getScoreFromApi';
import { checkForSavedBackup } from './init/checkForSavedBackup';
import { checkPeriodicKey } from './init/checkPeriodicKey';
import { checkPproVersion } from './init/checkPproVersion';
import { fetchAndLoadExtendScriptFunctions } from './init/fetchAndLoadScriptInstructions';
import { initHeartbeat } from './init/heartbeat';
import { registerCEPListeners } from './init/registerCEPListeners';
import { updateIsInCEPEnv } from './init/updateIsInCEPEnv';
import { login } from './license/login';
import { clearPreviousLogs, logger } from './logger';
import {
  autocutStoreVanilla,
  setAutocutStore,
  syncAllStoreFromLocalStorage,
} from './zustand';
import { checkForV2Mark } from './init/checkForV2Mark';
import { autocutApi } from './http.utils';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('initAutoCut', level, message, objects);
};

export const initAutocut = async () => {
  let errorCode = 0;

  try {
    CEPProcessManager.reset();
    errorCode = 50;

    //CEP ENV CHECK
    await updateIsInCEPEnv();
    errorCode = 100;

    keyRegisterOverride();
    errorCode = 125;

    await loadUserLocalFonts();
    errorCode = 130;

    fillLocalStorageWithPersistanceStorage();
    errorCode = 150;

    clearPreviousLogs();
    errorCode = 170;

    syncAllStoreFromLocalStorage();
    errorCode = 175;

    await refreshClientInfos();
    errorCode = 185;

    //LOGIN
    let key = autocutStoreVanilla().user.key;
    if (!key) key = getKey(false);
    errorCode = 200;
    if (key) {
      const keyCheckResponse = await isKeyValid(key);
      errorCode = 201;

      if (keyCheckResponse.result) {
        errorCode = 202;
        const license = {
          ...keyCheckResponse.licence,
          key,
        };
        errorCode = 203;
        await login({
          ...keyCheckResponse.accessTokens,
          key,
          license,
          additionalInformations: keyCheckResponse.additionalInformations,
        });
        errorCode = 204;
        await initAfterLogged().catch(e => {
          errorCode = 205;
          throw e;
        });
      } else {
        errorCode = 206;
      }
    }
  } catch (error: any) {
    manageError({
      error: new IncrementalError(error, 'initAutocut'),
      additionalData: `errorCode: ${errorCode}`,
    });
  }
};

export const initAfterLogged = async () => {
  let errorCode = 0;
  const autocutState = autocutStoreVanilla();

  try {
    //Setup periodic key check (each 10 minutes)
    const checkPeriodicKeyInterval = setInterval(async () => {
      await checkPeriodicKey();
    }, 600000);
    setAutocutStore('utils.intervals', state => [
      ...state.utils.intervals,
      checkPeriodicKeyInterval,
    ]);

    await loadStartUpModals();

    //Fetch and run all the scripts
    if (!autocutState.ppro.isScriptLoaded)
      await fetchAndLoadExtendScriptFunctions().catch(e => {
        console.log('Unable to load ExtendScript functions');
        errorCode = 100;
        throw e;
      });

    await checkPproVersion();
    errorCode = 125;

    errorCode = 150;
    await registerCEPListeners();
    errorCode = 151;

    //Check for project backup and restore state variables if needed
    if (!autocutState.backup.isRestorable)
      await checkForSavedBackup().catch(e => {
        errorCode = 200;
        throw e;
      });

    //Fetch and restore the game related state variables
    await getScoreFromApi().catch(e => {
      errorCode = 300;
      throw e;
    });

    await manuallyRefreshSequence().catch(e => {
      errorCode = 500;
      throw e;
    });

    errorCode = 600;
    await initHeartbeat();

    errorCode = 700;
    const isTrial = autocutState.user.key?.startsWith('Trial_');
    if (isTrial) {
      addModalToQueue(ModalType.GamingTrial);
    } else {
      if (await checkForV2Mark()) {
        void autocutApi
          .get('launch-modals/launch-v2-modal')
          .then((response: any) => {
            const launchModal = response.data;
            const lastSeenModalDate =
              autocutStoreVanilla().user.lastSeenLaunchV2Modal;
            if (lastSeenModalDate < launchModal.releaseDate) {
              addModalToQueue(ModalType.LaunchV2);
            }
          });
      } else {
        addModalToQueue(ModalType.OnboardingMigration);
      }
    }
  } catch (error: any) {
    manageError({
      error: new IncrementalError(error, 'initAfterLogged'),
      additionalData: `errorCode: ${errorCode}`,
    });
  }
};
