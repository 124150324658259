import { getModeStatTrait } from '@autocut/enums/modes.enum';
import { StatType } from '@autocut/types/StatType.enum';
import logLevel from '@autocut/types/logLevel.enum';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import { isFirstCut, isFirstCutToday } from '../localStorage.utils';
import { logger } from '../logger';
import { sendStats } from '../stats.util';
import { manageError } from '@autocut/utils/manageError';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { checkForV2MarkAndSignal } from '@autocut/utils/init/checkForV2Mark';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('handleStatsPreCut', level, message, objects);
};

export const handleStatsPreProcess = async () => {
  try {
    await checkForV2MarkAndSignal();

    const autocutStates = autocutStoreVanilla();
    const mode = autocutStates.ui.process.mode;
    const license = autocutStates.user.license;
    const StatCategoryValue = getModeStatTrait(mode);

    await sendStats({
      type: StatType.PROCESS_STARTED,
      value: 1,
      traits: {
        mode: StatCategoryValue,
        width: autocutStates.sequence.infos?.settings.width || -1,
        height: autocutStates.sequence.infos?.settings.height || -1,
        duration: autocutStates.sequence.infos
          ? autocutStates.sequence.infos.end -
            autocutStates.sequence.infos.start
          : -1,
        nbClipsSelected: autocutStates.sequence.infos
          ? [
              ...autocutStates.sequence.infos.audioTracks,
              ...autocutStates.sequence.infos.videoTracks,
            ]
              .map(track => track.nbClipsSelected)
              .reduce((acc, current) => acc + current, 0)
          : -1,
        nbClips: autocutStates.sequence.infos
          ? [
              ...autocutStates.sequence.infos.audioTracks,
              ...autocutStates.sequence.infos.videoTracks,
            ]
              .map(track => track.nbClips)
              .reduce((acc, current) => acc + current, 0)
          : -1,
        ...(license?.type === 'trial' && isFirstCut()
          ? { firstTrialCut: 1 }
          : {}),
        ...(isFirstCutToday() ? { firstCutToday: 1 } : {}),
      },
    });
  } catch (error: any) {
    //Non-blocking error, cache is just not deleted
    logMessage(
      logLevel.error,
      'An error has occured when sending pre-process stats : ' + error
    );

    manageError({
      error: new IncrementalError(error, 'handleStatsPreProcess'),
    });
  }
};
