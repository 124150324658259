import { os, fs } from '@autocut/lib/cep/node';
import path from 'path';
import { july } from '../stats.util';
import { StatType } from '@autocut/types/StatType.enum';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';

const getDirPath = async () => {
  const homeDir = os.homedir();
  const platform = os.platform();
  let dirPath;

  switch (platform) {
    case 'darwin': {
      dirPath = path.join(homeDir, 'Library', 'Caches', 'fr.toolpad.autoCut');
      break;
    }

    case 'win32': {
      dirPath = path.join(homeDir, 'AppData', 'Roaming', 'fr.toolpad.autoCut');
      break;
    }

    default:
      dirPath = path.join(homeDir, '.logs', 'fr.toolpad.autoCut');
      break;
  }

  try {
    if (!fs.existsSync(dirPath)) {
      fs.mkdirSync(dirPath);
    }
  } catch (err: any) {}

  return dirPath;
};

export const checkForV2MarkAndSignal = async () => {
  try {
    if (await checkForV2Mark()) {
      console.log('AutoCut V2 previously launched');
      await july.event(StatType.RELAUNCHED_V1, 1);
      setAutocutStore('user.isV1RelaunchedStatSended', true);
    }
  } catch {}
};

export const checkForV2Mark = async () => {
  try {
    const alreadySent = autocutStoreVanilla().user.isV1RelaunchedStatSended;
    if (alreadySent) return;

    const dirPath = await getDirPath();
    const flagPath = path.join(dirPath, 'AutoCutV2Flag.txt');

    return fs.existsSync(flagPath);
  } catch {}
};
