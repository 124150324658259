import React, { useMemo } from 'react';

import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { setAutocutStore } from '@autocut/utils/zustand';
import { getRandomAuthor } from '@autocut/components/atoms/Author/utils';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';

import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import { Author } from '@autocut/components/atoms/Author/Author';
import { NewFeaturesList } from '../OnboardingMigrationModal/NewFeature';

export const RELOAD_MODAL_TIMEOUT = 30 * 60 * 1000; // 30 minutes

const RestartAutoCutModal = () => {
  const randomAuthor = useMemo(() => getRandomAuthor(), []);

  return (
    <Modal
      title={
        <TranslatedMessage
          id="reload_autocut_modal_title"
          defaultMessage="Reload AutoCut"
        />
      }
      footer={
        <FlexContainer flexDirection="column" gap={8}>
          <Button
            color={colors.primary600}
            onClick={() => {
              openLinkInBrowser('https://www.autocut.com/en/download-beta/');
            }}
          >
            <TranslatedMessage
              id="onboarding_migration_upgrade"
              defaultMessage="Upgrade now to AutoCut 2.0"
            />
          </Button>
          <Button
            onClick={() => {
              window.location.href = window.location.origin;
            }}
            variant="secondary"
          >
            <TranslatedMessage
              id="reload_autocut_modal_cta"
              defaultMessage="Reload AutoCut"
            />
          </Button>
        </FlexContainer>
      }
      closeModalFunction={() => {
        setAutocutStore('utils.openedTimestamp', Date.now());
        setAutocutStore('ui.openedModalName', '');
      }}
    >
      <FlexContainer flexDirection="column" gap={12}>
        <FormSection title={<Author author={randomAuthor} />}>
          <PaddedSection>
            <TranslatedMessage
              id="reload_autocut_modal_text"
              defaultMessage="We understand that those regular reloads can disrupt your workflow.{br}They are due to technological limitations in the current version, something we've been unable to resolve within V1. {br}{br}But we have exciting news: AutoCut 2.0 is here, offering a seamless experience without the need for interruptions."
            />
          </PaddedSection>
        </FormSection>

        <NewFeaturesList />
      </FlexContainer>
    </Modal>
  );
};

export default RestartAutoCutModal;
