import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React from 'react';

import css from './TaskList.module.scss';
import { FaCheck } from 'react-icons/fa6';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { TrialTask } from '@autocut/types/TrialTask';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { colors } from '@autocut/designSystem/colors';
import { MdPlusOne } from 'react-icons/md';

export type TaskListProps = {
  tasks: TrialTask[];
};

type ItemProps = TaskListProps['tasks'][number];

const Item = ({
  textId,
  completed,
  link = '',
  isAnimated = false,
}: ItemProps) => {
  const classNames = `${css.iconContainer} ${isAnimated ? css.animated : ''} ${
    completed ? css.completed : ''
  }`;
  const icon = completed ? (
    <FaCheck color="white" />
  ) : (
    <MdPlusOne color={colors.gray800} />
  );
  return (
    <FlexContainer gap={8} alignItems="center">
      <div className={classNames}>
        <FlexContainer
          className={css.icon}
          alignItems="center"
          justifyContent="center"
        >
          {icon}
        </FlexContainer>
      </div>

      <Text variant="textSm.medium" color={colors.gray100}>
        <TranslatedMessage
          id={textId}
          defaultMessage={textId}
          //VALUE LINK
          values={{
            link: chunk => (
              <span
                className={css.link}
                onClick={() => openLinkInBrowser(link)}
              >
                {chunk}
              </span>
            ),
          }}
        />
      </Text>
    </FlexContainer>
  );
};

export const TaskList = ({ tasks }: TaskListProps) => {
  return (
    <FlexContainer gap={8} flexDirection="column">
      {tasks.map((task, index) => (
        <Item key={index} {...task} />
      ))}
    </FlexContainer>
  );
};
